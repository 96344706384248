import { Auth } from "@aws-amplify/auth";

/**
 * These values will become dynamic per each env once the ErgonaCDK is complete.
 */
const REGION = "us-east-1";
const IDENTITY_POOL_ID = "us-east-1:9283c864-94b3-4322-9164-a04347d45315";
const USER_POOL_ID = "us-east-1_OzKP9DAUo";
const WEB_CLIENT_ID = "39pbfmjouu2t6dh3pc9os6uoaq";
const DOMAIN = `allegro-gamma-na.auth.us-east-1.amazoncognito.com`;

export async function Init() {
  const config = {
    stage: "gamma",
    aws_cognito_region: REGION,
    aws_cognito_identity_pool_id: IDENTITY_POOL_ID,
    aws_user_pools_id: USER_POOL_ID,
    aws_user_pools_web_client_id: WEB_CLIENT_ID,
    Auth: {
      region: REGION,
      identityPoolId: IDENTITY_POOL_ID,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: WEB_CLIENT_ID,
    },
    oauth: {
      domain: DOMAIN,
      redirectSignIn: "https://gamma.na.headphones.whs.amazon.dev",
      redirectSignOut: "https://gamma.na.headphones.whs.amazon.dev",
      scope: ["openid", "profile", "email"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}
